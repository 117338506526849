import type {
    TravelFormDataV1Attributes,
    TravelFormDestinationResult,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormDesktopFormConfiguration } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDestinationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-desktop/travel-form-destination-overlay-desktop';
import useTravelFormDestinationOverlay from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/use-travel-form-destination-overlay';

type TravelFormDestinationDesktopContainerProps = {
    destination: TravelFormDataV1Attributes['filters']['destination'] | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isInputDisabled: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => void;
};

export default function TravelFormDestinationOverlayContainerDesktop({
    destination,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isInputDisabled,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
}: TravelFormDestinationDesktopContainerProps) {
    const {
        getDestinationListString,
        hasSelectionChanged,
        initialDestinationFilter,
        isLoading,
        onDestinationResultClick,
        resetSearch,
        result,
        searchDestination,
        searchQuery,
        selectedDestinations,
    } = useTravelFormDestinationOverlay({
        destinationRequestTarget: destinationRequestTarget ?? null,
        queryConfig: {
            enabled: isOverlayVisible,
        },
        selectedItems: destination?.selectedItems,
        selectionType: destination?.selectionType,
    });

    const isSingleSelect = destination?.selectionType === 'single';

    const selectedDestinationsByName = isSingleSelect
        ? []
        : selectedDestinations.map((destination) => destination.name);

    const initialSelectedItems = initialDestinationFilter?.selectedItems;

    const inputConfiguration = formConfiguration.fields?.destination;
    const isDataIncomplete = !destination || !destinationChangeRequestTarget || !destinationRequestTarget;
    const ctaText = selectedDestinations.length > 1 ? destination?.ctaText.plural : destination?.ctaText.singular;
    const inputLabel = destination?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = destination?.placeholderText ?? inputConfiguration?.placeholderText;

    const optimisticPlaceholder = React.useMemo(() => {
        if (selectedDestinations.length === 1 && selectedDestinations[0] && selectedDestinations[0].isDefault) {
            return null;
        }

        if (selectedDestinations.length === 0) {
            if (initialSelectedItems?.length === 1 && initialSelectedItems[0]?.isDefault) {
                return null;
            }
            return placeholderText;
        }

        return selectedDestinations
            .reduce<string[]>((acc, destination) => {
                return destination.isDefault ? acc : [...acc, destination.title];
            }, [])
            .sort((destination1, destination2) => destination1.localeCompare(destination2))
            .join(', ');
    }, [initialSelectedItems, placeholderText, selectedDestinations]);

    const handleOverlayCtaClick = () => {
        if (!destination?.name) {
            return;
        }

        if (!hasSelectionChanged(selectedDestinations)) {
            return;
        }

        const listOfDestinations = getDestinationListString();
        if (!listOfDestinations.length) {
            onOverlayCtaClick(destination.name, null, destinationChangeRequestTarget);
            return;
        }
        onOverlayCtaClick(destination.name, listOfDestinations, destinationChangeRequestTarget);
        resetSearch();
    };

    const handleDestinationResultClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>,
        selectedDestination: TravelFormDestinationResult,
    ) => {
        onDestinationResultClick(event, selectedDestination, (destinationsListString) => {
            if (!destination?.name) {
                return;
            }
            onOverlayCtaClick(destination.name, destinationsListString, destinationChangeRequestTarget);
        });
    };

    const handleClose = () => {
        resetSearch();
        onOverlayClose();
    };

    const onInputChange = (value: string) => {
        if (!destinationRequestTarget) {
            return;
        }
        searchDestination(value, destinationRequestTarget);
    };

    return (
        <TravelFormInputWrapperDesktop
            autoFocus={true}
            ctaText={ctaText ?? 'Reiseziel übernehmen'}
            filterValue={optimisticPlaceholder ?? destination?.placeholderText ?? null}
            hasError={hasError}
            hideCta={isSingleSelect}
            inputSize={formConfiguration.inputSize}
            isInputDisabled={!isOverlayVisible || isInputDisabled}
            isLoading={isLoading || isDataIncomplete}
            isOverlayVisible={isOverlayVisible}
            label={inputLabel ?? 'Reiseziel'}
            onChange={onInputChange}
            onClear={resetSearch}
            onClick={() => onInputClick(inputName)}
            onClose={handleClose}
            onCtaClick={handleOverlayCtaClick}
            placeholder={
                isOverlayVisible
                    ? optimisticPlaceholder ?? 'Spot, Region, Land'
                    : optimisticPlaceholder ?? placeholderText ?? 'Spot, Region, Land'
            }
            qaId={`qa-travel-form-input-destination-desktop${!isDataIncomplete ? '-loaded' : ''}`}
            type={'input'}
        >
            <TravelFormDestinationDesktop
                isLoading={isLoading || isDataIncomplete}
                noResult={searchQuery.length > 0 && result?.noResult ? result.noResult : null}
                onDestinationResultClick={handleDestinationResultClick}
                resultSet={result?.resultSet ?? []}
                selectedDestinationsByName={selectedDestinationsByName}
            />
        </TravelFormInputWrapperDesktop>
    );
}
