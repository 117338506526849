import React from 'react';

import { MinusIcon } from '@/assets/icons/minus';
import { PlusIcon } from '@/assets/icons/plus';
import { decrement, increment } from '@/core/components/counter/counter-service';
import color from '@/core/features/styles/color';

import styles from './counter.module.scss';

export type CounterProps = {
    count: number;
    disableActiveState?: boolean;
    max?: number;
    min?: number;
    onDecrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onIncrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    steps?: number;
};

export default function Counter({
    count,
    disableActiveState = false,
    max,
    min = 0,
    onDecrease,
    onIncrease,
    steps = 1,
}: CounterProps) {
    const isMinusIconActive = !disableActiveState && count > min;
    const isPlusIconActive = !disableActiveState && max && count < max;

    return (
        <div className={`flex-space-between-horizontal ${styles.counter}`}>
            <button
                className={styles.button}
                data-qa-id={'qa-minus-button'}
                onClick={(event) => decrement(event, count, steps, min, onDecrease)}
            >
                <MinusIcon
                    color={isMinusIconActive ? color('endeavour') : color('dustyGray')}
                    height={16}
                    width={16}
                />
            </button>
            <div className={`flex-center ${styles.count}`}>{count}</div>
            <button
                className={styles.button}
                data-qa-id={'qa-plus-button'}
                onClick={(event) => increment(event, count, steps, onIncrease, max)}
            >
                <PlusIcon
                    color={isPlusIconActive ? color('endeavour') : color('dustyGray')}
                    height={16}
                    width={16}
                />
            </button>
        </div>
    );
}
